import { ServiceInfoCardChildInterface } from "../service-info-card-interface"

const op1 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Az egyre mobil központúbb területen is tartaniuk kell a lépést, ott is bővíteniük kell.  Új platformra volt szükségük ahhoz, hogy elérjék a mobileszközök felhasználóit. Ez az infrastruktúra és a fejlesztőcsapat gyors bővítését is jelentette.",
  displayXo: true,
  reverse: false,
  title: "Online élelmiszer vásárlás",
  picture: "/images/refs/asztalra/ref_color.png"
}

const op2 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Fejlesztőink a telepíthető weboldal mögött meghúzódó technikai stratégiára összpontosítva könnyen és gyorsan változtathatóvá tették a kialakítást. Emellett a webshop elérhető az alkalmazásboltokban is.",
  displayXo: true,
  reverse: true,
  title: "Mobilra telepíthető webáruház",
  picture: "/images/refs/asztalra/asztalra_2.png"
}

const op3 : ServiceInfoCardChildInterface = {
  boldDescription: "",
  bigImg: false,
  description: "Legyen szó számítógépről vagy mobiltelefonról, a digitális vásárlás élménye  teljesen a fogyasztóhoz igazodik. Minél több csatornán keresztül érheti el a vállalkozás a közönségét, annál jobb. A mobil központú fejlesztésnek köszönhetően növekedett a felhasználók visszatérése.",
  displayXo: true,
  reverse: false,
  title: "Több csatornás elérés",
  picture: "/images/refs/asztalra/asztalra_3.png"
}

export const opInfoCards = [
  op1,
  op2,
  op3
]

