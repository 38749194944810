import { Reference } from "../reference-interface"
import { opInfoCards } from "../../service-info-card-interface/used_by/asztalra-cards"

export const Asztalra : Reference = {
  monitorPic: "/images/imac_asztalra_template.png",
  siteName: "Asztalra.hu webshop",
  siteDescription: "Letisztult, modern felületettel növeltük az online eladásokat, és az alkalmazásként telepíthető weboldallal kényelmesebbé teszttük az ügyfeleik számára a bevásárlást.",
  siteUrl: "https://www.asztalra.hu/",
  cards: opInfoCards,
  referencePics: [
    "/images/refs/asztalra/1.jpg",
    "/images/refs/asztalra/ref.png",
    "/images/refs/asztalra/2.jpg",
  ],
  pageFunctions: [
    "Saját ERP",
    "Modern design",
    "Önálló adminfelület",
    "Telefonra telepíthető",
  ],
  reversePageFunctions: false,
  bigImg: '/images/asztalra_phones.png'
}
